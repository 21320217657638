/**
 * Url to api endpoint
 * https://create-react-app.dev/docs/adding-custom-environment-variables/
 * > Note: You must create custom environment variables beginning with REACT_APP_. Any other variables except NODE_ENV
 * will be ignored to avoid accidentally exposing a private key on the machine that could have the same name. Changing
 * any environment variables will require you to restart the development server if it is running.
 * @type {string}
 */
const API_URL = "https://prod.api.hhesle.com";
const GOOGLE_MAP_URL = "https://www.google.com/maps/search/?api=1&query=";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const ob = {
  apiUrl: API_URL,
  googleMapUrl: GOOGLE_MAP_URL,
  googleApiKey: GOOGLE_API_KEY,
};

export default ob;
